
























import { Component, Vue } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { PlayerStore } from '@/themes/v1/stores/player.store';
import { httpClient } from '@/core/http';
import { playerService } from '@/modules/player.service';
import { OnMounted } from '@/core/vue.types';

@Component
export default class Player extends Vue implements OnMounted {
  playerStore = getModule(PlayerStore, this.$store);

  loginProcessing = false;
  playerId = '50863';

  props = [
    'deviceLocale',
    'deviceModel',
    'gameLocale',
    'gameVersion',
    'platformType',
    'playerName',
    'loginId',
    'utcOffset',
    'timestamp',
    'hash',
  ];

  private playerIdStorageKey = 'sp.devtools.playerId';
  private autologinStorageKey = 'sp.devtools.autologin';

  mounted() {
    this.playerId = this.getPlayerId();
  }

  setPlayerId() {
    if (this.playerId.trim()) {
      this.playerStore.setPlayer(this.playerId.trim());
      window.localStorage.setItem(
        this.playerIdStorageKey,
        this.playerId.trim()
      );
    }
  }

  toggleAuth() {
    this.playerStore.setAuth(!this.playerStore.isAuth);
  }

  async login() {
    const timestamp = Math.floor(Date.now() / 1000);
    try {
      this.loginProcessing = true;
      const { data: hash } = await httpClient.post<string>(`/player/dev/hash`, {
        playerId: this.playerId,
        timestamp: `${timestamp}`,
      });
      const response = await playerService.login(
        this.playerId,
        `${timestamp}`,
        hash
      );
      this.playerStore.setToken(response.token);
    } finally {
      this.loginProcessing = false;
    }
  }

  private getPlayerId() {
    if (this.playerStore.playerId) {
      return this.playerStore.playerId;
    }
    if (window != null) {
      const saved = window.localStorage.getItem(this.playerIdStorageKey);
      if (saved != null) {
        return saved;
      }
    }
    return '50863';
  }
}
