














import { Component, Vue } from 'vue-property-decorator';

@Component
export default class PageInfo extends Vue {
  get url() {
    return this.$router.currentRoute.fullPath;
  }

  get href() {
    return location.href;
  }

  get size() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  get navigator() {
    return navigator;
  }
}
