import PageInfo from './page-info.vue';
import Player from './player.vue';
import LangSelector from './LangSelector.vue';
import Setup from './Setup.vue';

export interface ToolComponent {
  name: string;
  component: any;
}

export const tools: ToolComponent[] = [
  {
    name: 'Info',
    component: PageInfo,
  },
  {
    name: 'Player',
    component: Player,
  },
  {
    name: 'Lang',
    component: LangSelector,
  },
  {
    name: 'Setup',
    component: Setup,
  },
];
