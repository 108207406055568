




























import { Component, Vue } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { AppStore } from '@/themes/v1/stores/app.store';
import { ErrorWithStatus, SystemStore } from '@/core/system';

@Component
export default class Setup extends Vue {
  appStore = getModule(AppStore, this.$store);
  systemStore = getModule(SystemStore, this.$store);
  changePlatform() {
    const newPlatform =
      this.$route.params.platform === 'ingame' ? 'web' : 'ingame';
    this.$router.push({
      params: {
        platform: newPlatform,
      },
    });
  }

  toggle500() {
    if (this.$errorHandler?.error) {
      (this.$error as any).clear();
    } else {
      throw new ErrorWithStatus('Manual triggered error', 500);
    }
  }

  to404() {
    this.$redirect(
      { path: this.$routerHelper.withPlatformAndLang(this.$route, '404') },
      404
    );
  }

  to503() {
    this.$router.push(
      this.$routerHelper.withPlatformAndLang(this.$route, 'under-maintenance')
    );
  }
}
