





















import { Component, Vue } from 'vue-property-decorator';

@Component
export default class LangSelector extends Vue {
  timer = 1000;

  runAutochange() {
    this.stopAutochange();
    const timer = setInterval(() => {
      const lang = this.$i18n.locale;
      const current = this.$i18n.availableLocales.indexOf(lang);
      const next = (current + 1) % this.$i18n.availableLocales.length;
      console.log(`Change, ${current} -> ${next}`);
      this.$i18n.locale = this.$i18n.availableLocales[next];
    }, Number(this.timer));
    (window as any).autochange = timer;
    console.log(timer, this.autochange);
  }

  stopAutochange() {
    console.log((window as any).autochange);
    if ((window as any).autochange) {
      clearInterval(Number((window as any).autochange));
    }
    this.$i18n.locale = this.$route.params.lang;
  }

  get autochange() {
    if (window) {
      return (window as any).autochange;
    }
    return null;
  }

  set autochange(val) {
    (window as any).autochange = val;
  }
}
