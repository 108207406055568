export default {
  name: 'dynamic-component',
  functional: true,
  props: {
    props: Object,
    component: [Function, Object],
    on: Object,
  },
  render(createElement, context) {
    const { component, props = {}, on = {} } = context.props;
    return createElement(component, {
      props,
      on,
    });
  },
};
