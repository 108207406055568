import { AxiosInstance } from 'axios';
import { httpClient } from '@/core/http';

export class PlayerService {
  constructor(private readonly http: AxiosInstance) {}

  async login(playerId: string, timestamp: string, hash: string) {
    const { data } = await this.http.post<PlayerLoginResponse>(
      '/player/login',
      {
        playerId,
        timestamp: `${timestamp}`,
        hash,
      }
    );
    return data;
  }
}

export const playerService = new PlayerService(httpClient);

export interface PlayerLoginResponse {
  token: string;
  expired: string;
}
