
































import { Component, Vue } from 'vue-property-decorator';
import { tools, ToolComponent } from './tools';
import DynamicComponent from './dynamic-component';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const ClickOutside = require('vue-click-outside');

@Component({
  components: { DynamicComponent },
  directives: { ClickOutside },
})
export default class Devtools extends Vue {
  showTools = false;
  selectedTool: ToolComponent | null = null;

  close() {
    this.showTools = false;
  }

  get tools() {
    return tools;
  }

  selectTool(tool: ToolComponent) {
    this.selectedTool = this.selectedTool === tool ? null : tool;
  }

  stop() {
    return;
  }
}
